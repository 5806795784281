import request from '../utils/request';
export default {
	//配置
	getConfig(address) {
		return request.request({
			url: '/gg/config',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},

	//是否已参与（拼团）
	getIsJoin(address) {
		return request.request({
			url: '/gg/is-join',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},

	//持有信息
	getHoldList(address) {
		return request.request({
			url: '/gg/hold-list',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},

	//可拼团列表
	getMarketList(data,address) {
		console.log(data,"获得的数据");
		return request.request({
			url: '/gg/market',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
			params: data
		})
	},


	//发起拼团
	postInitiate(data, address) {
		return request.request({
			url: '/gg/initiate',
			method: "post",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
			data
		})
	},

	//团长券兑换GDB
	postExchangeGdb(data, address) {
		return request.request({
			url: '/gg/exchange-gdb',
			method: "post",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
			data
		})
	},

	//拼团
	postJoin(data, address) {
		return request.request({
			url: '/gg/join',
			method: "post",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
			data
		})
	},

	//参与拼团列表
	getJoinList(data,address) {
		return request.request({
			url: '/gg/join-list',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
			params: data

		})
	},
	//账单
	getBillList(data, address) {
		return request.request({
			url: '/gg/bill-list',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
			params: data
		})
	},

	//统计
	goodSummary(address) {
		return request.request({
			url: '/gg/summary',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language':  localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},

	//钱包列表
	walletList(address) {
		return request.request({
			url: '/reward/wallet-list',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},

	//获取平台配置
	config(address) {
		return request.request({
			url: '/index/config',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},

	//充值配置
	rechargeConfigs(address){
		return request.request({
			url: '/reward/recharge/configs',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},

    //入账标记
	entryMark(data, address) {
		return request.request({
			url: '/reward/entry-mark',
			method: "post",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
			data
		})
	},

	//提币配置
	withdrawConfigs(address){
		return request.request({
			url: '/order/withdraw/configs',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},

	//提币
	withdraw(data,address){
		return request.request({
			url: '/order/withdraw',
			method: "post",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
			data
		})
	},

	//等级
	levelInfo(address){
		return request.request({
			url: '/gg/level-info',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			}
		})
	},
	//转账配置
	transferconfigs(address){
		return request.request({
			url: '/transfer/configs',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			}
		})
	},
	//转账
	transferout(data,address){
		return request.request({
			url: '/transfer/out',
			method: "post",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
			data:data
		})
	},
	
}