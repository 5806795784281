<template>
    <div class="kuangchi">
        <van-popup v-model:show="contentshow" round closeable>
            <div class="contentshow">
                <div class="contentshow_title">
                    <span>{{ $t('lang.drop3') }}</span>
                    <div @click="contentshow = false">
                        <!-- <img src="@/assets/imgsnowball/x_icon.png" style="width: 14px; height: 14px;" alt=""> -->
                    </div>

                </div>
                <div class="contentshow_box">
                    <span style="margin-bottom: 43px;">{{ $t('lang.drop4')
                        }}</span>
                    <span>{{ $t('lang.drop5') }}</span>
                </div>
            </div>
        </van-popup>
        <div class="line1">
            <div class="l1_title">
                <span> AirDrop</span>
                <span>“</span>
                <img src="@/assets/img/airdrop/icon3.png" style="width: 44px; height: 44px;" alt=""
                    @click="contentshow = true">
                <span>”</span>
            </div>
            <!-- <div class="l1_lin">
                <div style="color: #9999; font-size: 12px;">
                    <span>{{ $t('lang.d127') }}</span>
                    <span>{{ $t('lang.d128') }}</span>
                </div>
                <div style="color: #fff; font-size: 20px;">
                    <span>{{ sdata.publish_amount }}</span>
                    <span>{{ sdata.production}} </span>
                </div>
            </div> -->
            <div class="l2_lin">
                <div class="l_box">
                    <img src="@/assets/img/airdrop/icon1.png" class="img" alt="">

                    <div class="l_main">
                        <span style="color: rgba(84, 84, 84, 1); font-style: 14px;">
                            {{ $t('lang.drop6') }}
                        </span>
                        <span style="color: rgba(69, 131, 255, 1); font-size: 20px;">
                            {{ gdtBanlace }}
                        </span>
                    </div>
                </div>
                <div class="l_box">
                    <img src="@/assets/img/airdrop/icon2.png" class="img" alt="">

                    <div class="l_main">
                        <span style="color: rgba(84, 84, 84, 1); font-style: 14px;">
                            {{ $t('lang.drop7') }}
                        </span>
                        <span style="color: rgba(69, 131, 255, 1); font-size: 20px;">
                            {{ claimcount }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="line2">

            <div class="l2_title">{{ $t('lang.drop8') }}</div>
            <div class="line2_list">
                <div class="line2_list_li">
                    <div>{{ $t('lang.drop9') }}</div>
                    <div>
                        {{ $t('lang.drop10') }}
                    </div>
                </div>
                <div class="line2_list_li">
                    <div v-if="rewardinfo.id!=0">
                        <img :src=" rewardinfo.hold_coin_logo" alt="">
                        <div>{{ rewardinfo.reward_coin_en }}</div>
                    </div>
                    <div v-else> -- -- </div>
                    <div v-if="rewardinfo.id != 0">{{ rewardinfo.reward_amount }}</div>
                    <div v-else> -- -- </div>
                </div>
            </div>
             <van-button class="line2_button" v-if="!claimLoading" @click="airdroprewardclaim">{{ $t('lang.drop12') }}</van-button>
             <van-button class="line2_button" loading v-else></van-button>
        </div>
    </div>
</template>

<script>
import Web3 from 'web3'
import { mapState } from 'vuex';
import { drop,goodApi,web3_api } from '@/api/index'
export default {
    name: '',
    components: {

    },
    data() {
        return {
            rewardinfo: {
                hold_examine:1
            },
            contentshow: false,
            gdtBanlace:0,
            claimcount:0,
            image_domain:'',
            claimLoading:false,
            gdtWeb3Banlace:0
        }
    },
    computed: {
        ...mapState(['account', 'lang']),
    },
    watch: {
        account() {
            this.init()
        },
        lang() {
            this.init()

        },

    },
    created() {
        this.init()
    },
    beforeDestroy() {
        window.clearInterval(this.timer)
        this.timer = null
    },
    destroyed() {
        window.clearInterval(this.timer)
        this.timer = null
    },
    methods: {
        async init() {
           if(this.account){
            Promise.all([
            this.rewardwallet(),
              this.getConfig(),
              this.airdroprewardclaimcount(),
              this.getERC20TokenBalance(),
            ])
           }
             
        },
        getConfig () {
      goodApi.config(this.account).then(res => {
        this.image_domain = res.data.image_domain
        this.airdroprewardinfo()
      })
    },
    getERC20TokenBalance(){
        web3_api.getERC20TokenBalance('0x47b3c2ba7f69879e5d081df62aee3cad4282b1b0',this.account).then(res=>{
             this.gdtWeb3Banlace = res
             console.log('res', res);
             
        })
    },
        rewardwallet(){
        
        drop.rewardwallet({coin_key:'GDT'},this.account).then(res=>{
              if(res.code == 200){
                this.gdtBanlace = res.data.usable
              }
        })
        },
        airdroprewardclaimcount(){
             drop.airdroprewardclaimcount(this.account).then(res=>{
                if(res.code == 200){
                this.claimcount = res.data
            }
            })
           
        },
        airdroprewardinfo() {
            drop.airdroprewardinfo(this.account).then(res => {
                if(res.code == 200){
                    this.rewardinfo = res.data
                }
               
            })
        },
        async airdroprewardclaim(){
           if(this.rewardinfo.id ==  0){
               this.$toast(this.$t('lang.drop13'))
               return
           }
      
           if(this.rewardinfo.hold_examine > this.gdtBanlace || this.rewardinfo.hold_examine > this.gdtWeb3Banlace) return this.$toast(this.$t('lang.drop14'));
            this.claimLoading = true
            let sign =null
            try {
                let web3 = new Web3(window.ethereum);
                 sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Airdrop Claim'), this.account)
            } catch (error) {
                this.claimLoading = false 
            }
           
             drop.airdroprewardclaim({signature:sign},this.account).then(res=>{
                         this.$toast(res.msg)
                         this.claimLoading = false 
                         if(res.code == 200){
                             this.init()
                         }
             })
        },
        tofenxiang() {

            this.$router.push({ name: 'fenxiang918' });
        },
        qidonwafun() {
            kuangchi.airdropget(this.account).then(res => {
                if (res.code == 200) {
                    this.init()
                }
                this.$toast(res.msg)
            })
        },
        tiqu() {
            this.$toast(this.$t('lang.swap65'))
        },

    },
}
</script>

<style lang="less" scoped>
.contentshow {
    width: 335px;
    background: linear-gradient(180deg, rgba(232, 239, 255, 1) 0%, rgba(232, 239, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);

    .contentshow_title {
        padding: 20px;
        padding-bottom: 17px;
        border-bottom: 1px solid rgba(242, 242, 242, 1);
        font-size: 16px;
        color: rgba(34, 34, 34, 1);
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 21px;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .contentshow_box {
        padding: 20px;
        padding-bottom: 40px;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 24px;
        color: rgba(34, 34, 34, 1);
        display: flex;
        flex-direction: column;

    }
}

.kuangchi {
    width: 100%;
    background: rgba(248, 250, 252, 1);

    .line1 {
        width: 100%;
        padding: 20px;

        .l1_title {

            color: rgba(60, 114, 241, 1);
            width: 100%;
            font-size: 28px;
            font-weight: 700;
            font-family: "HarmonyOSSansSC";
            text-align: center;
            margin-bottom: 4rem !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .l1_lin {
            margin-top: 20px;
            border: 1px solid #56606B;
            height: 95px;
            border-radius: 16px;
            opacity: 1;
            background: #26272b;
            background: linear-gradient(90deg, #26272E 2%, #26272E 100%);
            padding: 20px 15px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                display: flex;
                justify-content: space-between;
            }
        }

        .l2_lin {
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 111px;
            margin-top: 45px;

            .l_box {
                width: 48%;
                height: 100%;
                position: relative;
                border-radius: 8px;
                opacity: 1;
                background: linear-gradient(180deg, rgba(87, 137, 255, 0.18) 0%, rgba(255, 255, 255, 0) 100%);


                .img {
                    width: 99.86px;
                    height: 99.86px;

                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .button {
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: "PingFang";
                }

                .l_main {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 100%);
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .line2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 30px 30px 0 0;
        opacity: 1;
        background: rgba(255, 255, 255, 1);
        box-sizing: border-box;
        padding: 20px;
        position: relative;
        .line2_button{
            font-size: 16px;
font-weight: bold;
color: #fff;
             width: 100%;
             height: 60px;
opacity: 1;
border-radius: 16px;
background: rgba(60, 114, 241, 1);
        }

        .line2_list {
            width: 100%;
            padding: 11px 20px;

            .line2_list_li {
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &>div {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 700;
                    color: rgba(0, 0, 0, 0.8);
                    img {
                        width: 34px;
                        height: 34px;
                        border-radius: 50%;
                        margin-right: 8px;
                    }

                }
            }
        }

        .l2_title {
            color: rgba(5, 5, 5, 1);

            font-size: 20px;
            font-weight: 700;
            font-family: "HarmonyOSSansSC";
            text-align: center;
            padding-bottom: 14px;
            border-bottom: 1px solid rgba(248, 250, 252, 1);
        }



    }
}
</style>