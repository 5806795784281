import request from '../utils/request';
export default {

	airdroprewardinfo(address) {
		return request.request({
			url: '/airdrop/reward/info',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},
    airdroprewardlist(data,address) {
		return request.request({
			url: '/airdrop/reward/list',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
            params:data
		})
	},
    airdroprewardclaimcount(address) {
		return request.request({
			url: '/airdrop/reward/claim-count',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
		})
	},
    airdroprewardclaim(data,address) {
		return request.request({
			url: '/airdrop/reward/claim',
			method: "post",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
            data:data
		})
	},
    rewardwallet(data,address) {
		return request.request({
			url: '/reward/wallet',
			method: "get",
			headers: {
				Authorization: address,
				'Show-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-CN',
			},
            params:data
		})
	},
}