<template>
  <div class="navbar">
    <van-nav-bar
  :title="$t($route.meta.title) "
  left-text=""
  right-text=""
  :left-arrow="true"
  :border="false"
  @click-left="onClickLeft"
  @click-right="onClickRight"
>
  <template #left>
    <div style="transform: rotate(90deg); "><van-icon name="down" size="20" color="rgba(34, 34, 34, 1)" /> </div>
  </template>

</van-nav-bar>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  },
  mounted() {
       console.log('4324324324',this.$route);
  },
  methods: {
    onClickLeft() {
     this.$router.go(-1)
    },
    onClickRight() {
      Toast('按钮');
    },
  },
}

</script>
<style scoped lang='less'>
/deep/.van-nav-bar__title{
    font-weight: bold;
    font-size: 18px;
}
</style>