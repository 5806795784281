<template>
    <div class="list">
        <div class="list_li">
          <div class="list_li_title">{{ $t('lang.home38') }}</div>
          <div class="list_li_title">{{ $t('lang.home39') }}</div>
          <div class="list_li_title">{{ $t('lang.home40') }}</div>
        </div>
        <div class="list_li" v-for="(item,index) in quoteList" :key="index">
          <div style="  display: flex;
  align-items: center;">
            <div class="list_li_logo">
                
                <img :src="item.coin_logo" class="list_li_logo_box" alt="">
                <img src="@/assets/tokenlogo/polygon.png" class="list_li_logo_abs" alt="">
               
            </div>
            <div style="font-size: 14px;">{{ item.coin }}</div>
          </div>
             
              <div class="list_li_num">${{ item.last }}</div>  
              <div>
                <div :class="isNegativeSign(item.percent)?'list_li_numRate list_li_numRate_red': 'list_li_numRate'"  style="float: right;">{{item.percent == '%'|| item.percent =='' ? '--':item.percent}}</div>
              </div>
              
        </div>
       
    </div>
  </template>
  
  <script>
  export default {
    data () {
      return {
  
      }
    },
    props: {
      quoteList:{
          type:Array,
          default:function () {
        return [];
      }
      }
    },
    components: {
  
    },
    mounted() {
  
    },
    methods: {
       isNegativeSign(str) {
  return str.charAt(0) === '-' || str[0] === '-';
}
    },
  }
  
  </script>
  <style scoped lang='less'>
  .list{
      width: 100%;
      padding-top: 16px;
      .list_li{
          margin-bottom: 22px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &>div{
              flex: 1;
              text-align: center;
          }
          &>div:first-child{
               text-align: left;
          }
          &>div:last-child{
               text-align: right;
          }
          .list_li_title{
              font-size: 12px;
              font-weight: 700;
              color: rgba(161, 161, 161, 1);
          }
          .list_li_num{
              font-size: 14px;
              color: #000;
          }
          .list_li_numRate_red{
              background: rgb(223, 57, 57) !important;
          }
          .list_li_numRate{
              width: 74px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  background: rgba(20, 182, 133, 1);
          }
          .list_li_logo{
              position: relative;
              width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;

               .list_li_logo_box{
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  margin-right: 10px;
               }
               .list_li_logo_abs{
                  position: absolute;
                  right: -5px;
                  bottom: -5px;
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
               }
          }
      }
  }
  </style>