<template>
  <div class="home_page">
    <div class="snowballmain">
      <van-swipe class="my-swipe" v-if="bannerList.length > 0" :autoplay="3000" indicator-color="white" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index">
          <img :src="`${image_domain}${item.Image}`" alt="" class="homeimg" />
        </van-swipe-item>
      </van-swipe>
      <img v-else  src="@/assets/img/test_banner.png" alt="" class="homeimg" />

      <div class="routerlink">
        <div class="router_li" v-for="(item, index) in routerlist" @click="bNav(item.router, item.i)" :key="index">
          <div class="imgbox">
            <div class="imgbox_item">
              <img :src="item.image" alt="" />
            </div>
          </div>

          <span>{{ $t(item.name) }}</span>
        </div>
        <router-link class="notice" :to="{ name: 'xiangqin', query: { id: noticeData.group_id } }">
          <div style="color: rgba(0, 0, 0, 1); font-weight: bold; padding-right: 10px; margin-right: 10px; border-right: 1px solid rgba(53, 58, 74, 1)">NEWS</div>
          <span style="color: rgba(60, 114, 241, 1)">{{ noticeData.title }}</span>
          <router-link :to="{ name: 'gongao' }" class="shoyi" style="background: rgba(80, 80, 80, 0.2)">
            <div style="transform: rotate(-90deg); color: #fff">
              <van-icon name="down" />
            </div>
          </router-link>
        </router-link>
      </div>
      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">
        <div class="page_title_item">{{ $t('lang.home1') }}</div>
      </div>
      <div class="zichanlist">
        <div class="li_title">
          <div>{{ $t('lang.home2') }}</div>
          <div>{{ $t('lang.home3') }}</div>
          <div>{{ $t('lang.home4') }}</div>
        </div>
        <div>
          <div class="li_con" v-for="(item, index) in assetsList" style="color: #000" :key="index">
            <div class="li1">
              <img :src="image_domain + item.logo" alt="" />
              <span>{{ item.coin_en }}</span>
            </div>
            <div>{{ item.usable }}</div>
            <div>{{ item.freeze }}</div>
          </div>
        </div>
      </div>
      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">
        <div class="page_title_item">PHO{{ $t('lang.home5') }}</div>
      </div>
      <div class="rwabox">
        <div class="rwabox_top">
          <div class="rwabox_top_item">
            <div class="rwabox_top_item_title">{{ $t('lang.home6') }}</div>
            <div class="rwabox_top_item_num"><span style="font-size: 12px">$</span> {{ PHOprice }}</div>
            <div class="rwabox_top_item_button" @click="bNav('Swap')">
              <img src="@/assets/img/jioayi_icon.png" alt="" />
              <span>{{ $t('lang.home7') }}</span>
            </div>
          </div>
          <div class="rwabox_top_item">
            <div class="rwabox_top_item_title">{{ $t('lang.home8') }}</div>

            <div class="rwabox_top_item_num">29000</div>
            <div class="rwabox_top_item_button" style="background: rgba(0, 0, 0, 1)" @click="bNav('')">
              <img src="@/assets/img/zhuzao_icon.png" alt="" />
              <span>{{ $t('lang.home9') }}</span>
            </div>
          </div>
        </div>
        <div class="rwabox_line" style="margin-top: 14px; margin-bottom: 10px">
          <div class="rwabox_line_left">PHO{{ $t('lang.home10') }}：</div>

          <div class="rwabox_line_right">{{ $t('lang.home11') }}1.5%</div>
        </div>
      </div>
      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">
        <div class="page_title_item">{{ $t('lang.home12') }}</div>
      </div>
      <div class="assets_box">
        <div class="box_title">{{ $t('lang.home13') }}</div>
        <div class="box_list">
          <AssetsIndex :quoteList="quoteList"></AssetsIndex>
        </div>
      </div>
      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">
        <div class="page_title_item">{{ $t('lang.home14') }}GoodSwap</div>
      </div>
      <div class="aboutSwap">
        <img src="@/assets/img/aboutswap.png" class="aboutSwap_img" alt="" />

        <div class="aboutSwap_box">
          {{ $t('lang.home15') }}
        </div>
      </div>
      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">
        <div class="page_title_item">GoodSwap{{ $t('lang.home16') }}</div>
      </div>

      <div class="Advantage">
        <div class="Advantage_box">
          <img src="@/assets/img/Advantage1.png" alt="" />
          <div class="Advantage_box_title">{{ $t('lang.home17') }}</div>
          <div class="Advantage_box_text">{{ $t('lang.home18') }}</div>
        </div>
        <div class="Advantage_box">
          <img src="@/assets/img/Advantage2.png" alt="" />
          <div class="Advantage_box_title">{{ $t('lang.home19') }}</div>
          <div class="Advantage_box_text">{{ $t('lang.home20') }}</div>
        </div>
        <div class="Advantage_box">
          <img src="@/assets/img/Advantage3.png" alt="" />
          <div class="Advantage_box_title">{{ $t('lang.home21') }}</div>
          <div class="Advantage_box_text">{{ $t('lang.home22') }}</div>
        </div>
        <div class="Advantage_box">
          <img src="@/assets/img/Advantage4.png" alt="" />
          <div class="Advantage_box_title">{{ $t('lang.home23') }}</div>
          <div class="Advantage_box_text">{{ $t('lang.home24') }}</div>
        </div>
      </div>
      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">
        <div class="page_title_item">{{ $t('lang.home25') }}</div>
      </div>
      <div class="partner_list">
        <div class="partner">
          <div class="partner_img">
            <div class="img" v-for="(item, index) of institutionsListB" :key="index">
              <img :src="item.image" />
            </div>
          </div>
        </div>
      </div>
      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">
        <div class="page_title_item">{{ $t('lang.home26') }}</div>
      </div>
      <div class="partner_list" >
     
        <div class="partner">
          <div class="partner_img">
            <div class="img" v-for="(item, index) of institutionsListC" :key="index">
              <img :src="item.image" />
            </div>
          </div>
        </div>

        <div class="Icon_img">
          <img v-for="(item, index) of institutionsListIcon" :key="index" :src="item" class="img" />
        </div>
        <div class="reserved">
          <span>Copyright@2024 PHOswap.All rights reserved.</span>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapState } from 'vuex';
import { goodApi, basic, web3_api } from '../../api/index';
import AssetsIndex from '@/components/AssetsIndex.vue';

export default {
  data() {
    return {
      noticeData: {},
      noticeBoolShow: false,
      image_domain:'',
      bannerList:[],
      assetsList: [],
      quoteList:[],
      PHOprice:0,
      routerlist: [
        {
          image: require('@/assets/homelogo/replenish_icon.png'),
          name: 'lang.home27',
          router: 'Recharge',
          i: '0',
          IsOpen: true,
        },
        {
          image: require('@/assets/homelogo/transfer_icon.png'),
          name: 'lang.home28',
          router: 'Recharge',
          i: '1',
          IsOpen: true,
        },
        {
          image: require('@/assets/homelogo/bill_icon.png'),
          name: 'lang.home29',
          router: 'RechargeBill',
          IsOpen: true,
        },
        {
          image: require('@/assets/homelogo/Transfers_icon.png'),
          name: 'lang.home41',
          router: 'Recharge',
          i: '2',
          IsOpen: true,
        },
      ],
      page: 1,

      institutionsListA: [
        require('@/assets/img/icon/icon1.png'),
        require('@/assets/img/icon/icon2.png'),
        require('@/assets/img/icon/icon3.png'),
        require('@/assets/img/icon/icon4.png'),
        require('@/assets/img/icon/icon5.png'),
        require('@/assets/img/icon/icon6.png'),
      ],
      institutionsListB: [
        {
          image: require('@/assets/img/slowmist.png'),
        },
        {
          image: require('@/assets/img/certik.png'),
        },
      ],
      institutionsListC: [
        {
          image: require('@/assets/img/binance.png'),
        },
        {
          image: require('@/assets/img/okchain.png'),
        },
        {
          image: require('@/assets/img/trustwallet.png'),
        },
        {
          image: require('@/assets/img/metamask.png'),
        },
        {
          image: require('@/assets/img/coinmarketcap.png'),
        },
        {
          image: require('@/assets/img/bitkeep.png'),
        },
        {
          image: require('@/assets/img/tokenpocket.png'),
        },
        {
          image: require('@/assets/img/coingecko.png'),
        },
        {
          image: require('@/assets/img/imtoken.png'),
        },
        {
          image: require('@/assets/img/openchat.png'),
        },
      ],
      institutionsListIcon: [require('@/assets/img/IconA.png'), require('@/assets/img/IconB.png'), require('@/assets/img/IconC.png'), require('@/assets/img/IconD.png')],
    };
  },
  components: {
    AssetsIndex,
  },
  created() {
      this.init();
      this.scrollLoad();
      this.scrollLoadmain();
  },
  mounted() {
    window.addEventListener('scroll', this.scrollLoad, true);
    window.addEventListener('scroll', this.scrollLoadmain, true);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    window.removeEventListener('scroll', this.scrollLoad, true);
    window.removeEventListener('scroll', this.scrollLoadmain, true);
  },
  watch: {
    account() {
      this.init();
    },
    lang() {
      this.init();
    },
  },

  methods: {
    bNav(router, i = '') {
      if (router != '') {
        if (i != '') {
          this.$router.push({ name: router, query: { id: i } });
        } else {
          this.$router.push({ name: router });
        }
      } else {
        // this.$toast(this.$t('lang.swap65'));
      }
    },
    basicbannerlist(){
      basic.basicbannerlist(this.account).then(res=>{
        if(res.code ==200){
          this.bannerList = res.data == null ? [] : res.data;
        }
          
      })  
    },
    indexquote(){
      basic.indexquote(this.account).then(res=>{
              if(res.code == 200){
                    this.quoteList = res.data
                    this.quoteList.forEach(item=>{
                      item.coin_logo = this.image_domain + item.coin_logo
                    })
              }
      })
    },

   async getPHOPrice(){
    this.PHOprice=  await web3_api.getCurrentPairPrice(['0xabfedadf69f6b692f6c1a0fcb791866eebddc10e'],'0xb3718900e261abaa78a00995c5a061731a5eb53c',18,6)
    },
    

    
    getConfig () {
      goodApi.config(this.account).then(res => {
        this.image_domain = res.data.image_domain
        this.getCurrencyList();
        this.indexquote();
      })
    },
    getCurrencyList () {
      goodApi.walletList(this.account).then(res => {
        if(res.code == 200){
          this.assetsList = res.data
        }
       
      });
    },
    basicnoticelatest(){
          basic.basicnoticelatest(this.account).then(res=>{
               if(res.code == 200){
                this.noticeData = res.data;
               }
          })
    },

    scrollLoad() {
      // 1.获取所有的box
      var boxList = document.querySelectorAll('.img');
      //2. 定义一个目标值
      var targetValue = window.innerHeight * 0.8;

      //3.获取每一个box距离浏览器顶部的值
      boxList.forEach(function (box) {
        var boxTop = box.getBoundingClientRect().top;

        if (boxTop <= targetValue) {
          box.classList.add('show-center');
        } else {
          box.classList.remove('show-center');
        }
      });
    },

    scrollLoadmain() {
      // 1.获取所有的box
      var boxList = document.querySelectorAll('.universal_box');
      //2. 定义一个目标值
      var targetValue = window.innerHeight * 0.8;

      //3.获取每一个box距离浏览器顶部的值
      boxList.forEach(function (box) {
        var boxTop = box.getBoundingClientRect().top;

        if (boxTop <= targetValue) {
          box.classList.add('show-center');
        } else {
          box.classList.remove('show-center');
        }
      });
    },
    async init() {
      this.basicbannerlist()
         this.basicnoticelatest()
        
         this.getConfig()
         this.getPHOPrice()
    },

    without() {
      this.$router.push({ name: 'Mining' });
    },
    toSwap() {
      this.$router.push({ name: 'Swap' });
    },

    
  },
  computed: {
    ...mapState(['account', 'lang', 'gongaoid', 'ColorCheckData']),
  },
};
</script>
  
  <style lang="less" scoped>
.assets_box {
  width: 100%;
  border-radius: 16px;

  background: rgba(242, 242, 242, 1);
  .box_title {
    font-size: 16px;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
    border-bottom: 1px solid rgba(255, 255, 255, 1);
  }
  & > div {
    padding: 17px 18px;
  }
}
.routemap2 {
  padding: 27px 9px 20px 9px;
  width: 100%;

  background-size: contain;
  height: 449px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  img {
    position: absolute;
    left: -40%;
    bottom: 16px;
    width: 228px;
    height: 133px;
  }
  .routemap2_line {
    width: 100%;

    text-align: right;
    &:nth-child(2n) {
      .routemap2_line_box {
        float: right;
      }
      text-align: left;
    }
    .routemap2_line_box {
      font-size: 12px;
      float: left;
      .routemap2_line_box_title {
        color: rgba(255, 255, 255, 1);
      }
      .routemap2_line_box_text {
        color: rgba(132, 138, 150, 1);
      }
    }
  }
}

.Advantage {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .Advantage_box {
    border-radius: 16px;
    background: rgba(242, 242, 242, 1);
    padding: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48%;
    margin-bottom: 16px;
    padding-top: 10px;
    img {
      width: 116px;
      height: 116px;
    }
    .Advantage_box_title {
      font-size: 18px;
      color: rgba(0, 0, 0, 1);
      font-weight: 700;
      margin-bottom: 10px;
    }
    .Advantage_box_text {
      font-size: 12px;
      line-height: 16.5px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}
.aboutSwap {
  width: 100%;
  position: relative;
  .aboutSwap_img {
    width: 75px;
    height: 75px;
    position: absolute;
    right: -30px;
    bottom: -20px;
  }
  .aboutSwap_box {
    border-radius: 16px;
    width: 100%;
    background: rgba(242, 242, 242, 1);
    padding: 10px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
  .aboutSwap_box1 {
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 1);
    background-size: cover;
  }
}
.rwabox {
  width: 100%;
  padding: 12px;
  border-radius: 16px;
  background: rgba(242, 242, 242, 1);

  .rwabox_line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 700;
    .rwabox_line_left {
      color: rgba(0, 0, 0, 0.6);
    }
    .rwabox_line_right {
      color: rgba(0, 0, 0, 1);
    }
  }
  .rwabox_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .rwabox_top_item {
      background: rgba(255, 255, 255, 1);
      width: 48%;
      padding: 10px 13px;
      border-radius: 8px;
      .rwabox_top_item_title {
        margin-bottom: 7px;
        font-size: 14px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.6);
      }
      .rwabox_top_item_num {
        font-size: 20px;
        color: rgba(0, 0, 0, 1);
        margin-bottom: 8px;
        font-weight: 700;
      }
      .rwabox_top_item_button {
        border-radius: 8px;
        background: rgba(60, 114, 241, 1);
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        font-weight: 700;
        img {
          width: 20px;
          height: 20px;
          margin-right: 9px;
        }
      }
    }
  }
}
.zhuzao_button {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(20, 20, 20, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 91px;
  height: 36px;
  opacity: 1;
  border-radius: 36px;
  padding: 2px;
  background: rgba(152, 232, 110, 1);
  .down_icon {
    transform: rotate(-90deg);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: var(--bg-color);
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.page_title {
  width: 100%;
  display: flex;

  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 27px;
  color: rgba(0, 0, 0, 1);
  position: relative;
  .page_title_item {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 36px;
      height: 2px;
      opacity: 1;
      background: linear-gradient(90deg, rgba(60, 114, 241, 1) 0%, rgba(60, 114, 241, 0) 100%);
    }
  }
}
.home_page {
  position: relative;
  background: rgba(255, 255, 255, 1);
  padding: 0 0 20px;
  overflow-x: hidden;
  margin-top: 30px;
  .gradient {
    font-weight: bold;
    background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .explanation {
    position: relative;
    font-size: 14px;
    // font-weight: bold;
    color: #b8b8b8;
    z-index: 1;
    padding: 30px 20px;
    p {
      color: var(--text-color);
      font-size: 28px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      text-align: left;
      line-height: 30px;
    }
    span {
      color: #5883bfff;
      font-size: 12px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
    }
  }
  .route_map {
    position: relative;
    margin: 0 auto;
    width: 334px;
    border: 1px solid rgba(52, 247, 244, 0.49);
    border-radius: 30px;
    opacity: 1;
    background: var(--box-bg-color);
    padding: 20px;
    z-index: 1;
    .map {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: var(--text-color);
      font-weight: bold;
      .routemap_img {
        display: block;
        width: 44px;
        height: 44px;
      }
    }
    .map_list {
      font-size: 14px;
      color: #848a96;
      p {
        color: var(--text-color) !important;
      }
      // font-weight: bold;
      div {
        margin-top: 16px;
      }
    }
    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: linear-gradient(90deg, #1a70fdff 0%, #26c3ffff 100%);
      font-weight: bold;
      margin-top: 30px;
      .more_img {
        display: block;
        width: 12px;
        height: 16px;
        margin-left: 6px;
      }
    }
  }
  .exhibit {
    // background: var( --text-color);
    margin: 10px 0 10px;
    padding: 40px 0;
    .hold {
      font-size: 18px;
      font-weight: bold;
      color: var(--text-color);
      text-align: center;
    }
    .exhibit_li {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: linear-gradient(90deg, #1a70fdff 0%, #26c3ffff 100%);
      margin-top: 20px;

      .li_top {
        font-size: 34px;
        font-weight: bold;
        span {
          background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .li_button {
        font-size: 12px;
        color: var(--text-color);
        margin-top: 6px;
      }
    }
    .specialmain {
      height: 120px;
      overflow: hidden;
      margin-top: 40px;
    }
    .special {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .special_li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 49%;
        position: relative;
        transition: transform 0.5s ease;
        .sli_yuan {
          width: 32px;
          height: 32px;
          position: absolute;
          left: 30px;
          border-radius: 50%;
        }
        .slitext {
          position: absolute;
          right: 40px;
          line-height: 10px;
          .wbnb {
            color: #5883bfff;
            font-size: 12px;
            font-weight: 500;
            font-family: 'PingFang';
          }
        }
      }
    }
  }
  .universal_box {
    overflow: hidden;
    border-radius: 20px;
    background: var(--box-bg-color);
    position: relative;
    padding: 20px;
    margin: 0 auto;
    margin-top: 20px;
    transition: transform 0.5s ease;
    width: 334px;
    .abs_img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 91.73px;
      height: 88.91px;
    }
    // border-radius: 30px;
    .clcaption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #c4c4c4;
      font-weight: bold;
      .pledge_img {
        display: block;
        width: 44px;
        height: 44px;
      }
    }
    .slogan {
      display: flex;
      flex-direction: column;
      font-size: 30px;
      font-weight: bold;
      color: var(--text-color);
      margin: 10px 0;
    }
    .box_button {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 40%;
      border-radius: 16px;
      background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
      font-size: 14px;
      color: var(--text-color);
      padding: 16px 0;
      margin-top: 20px;
      .box_arrow {
        display: block;
        width: 16px;
        height: 10px;
        margin-left: 8px;
      }
    }
  }
  .universal_box:nth-child(even) {
    transform: translateX(-250%);
  }

  /* 奇数 */
  .universal_box:nth-child(odd) {
    transform: translateX(250%);
  }

  .universal_box.show-center {
    transform: translateX(0);
  }
  .merchandise_bg {
    // background: url('~@/assets/img/merchandise_bg.png') no-repeat;
    background-size: 100% 100%;
  }
  .earncoins_bg {
    background: url('~@/assets/img/earncoins_bg.png') no-repeat;
    background-size: 100% 100%;
    .million {
      font-size: 14px;
      font-weight: bold;
      color: var(--text-color);
    }
  }
  .emission_bg {
    background: url('~@/assets/img/emission_bg.png') no-repeat;
    background-size: 100% 100%;
  }
  .market_bg {
    background: url('~@/assets/img/market_bg.png') no-repeat;
    background-size: 100% 100%;
  }
  .super_bg {
    //   background: url('~@/assets/img/super_bg.png') no-repeat;
    background-size: 100% 100%;
  }

  .partner_list {
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    // border: 1px solid #3399ff;
  
    display: flex;
    flex-direction: column;
    align-items: center;

    .partner {
      width: 100%;
      margin-bottom: 30px;
      .partner_title {
        font-size: 18px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: var( --text-color);
        text-align: center;
        margin-bottom: 20px;
      }
      .partner_img {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;
        .img {
          width: 45%;
          height: 47px;
          border-radius: 10px;
          margin-top: 14px;
          background: rgba(242, 242, 242, 1);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: transform 0.5s ease;
        }

        /* 偶数 */
        .img:nth-child(even) {
          transform: translateX(-250%);
        }

        /* 奇数 */
        .img:nth-child(odd) {
          transform: translateX(250%);
        }

        .img.show-center {
          transform: translateX(0);
        }

        img {
            width: 114px;
            height: 27px;
        }
        .partner_li {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48%;
          height: 52px;
          border-radius: 16px;
          padding: 10px;
          background: var( --text-color);
          margin-bottom: 14px;
        }
      }
    }
    .reserved {
      color: var( --text-color);
      font-size: 10px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      text-align: center;
    }
    .Icon_img {
      width: 159.89px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 40px;
      .img {
        width: 24px;
height: 21.74px;
      }
    }
  }
}
.inDapp {
  margin: 0 auto;
  width: 336px;
  height: 44px;
  margin-bottom: 49px;
  border-radius: 10px;
  opacity: 1;
  background: linear-gradient(90deg, #1a70fdff 0%, #26c3ffff 100%);
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  font-family: 'PingFang';
  text-align: center;
  line-height: 44px;
}
/deep/.van-icon-success {
  color: var(--text-color) !important;
}
.snowballmain {
  padding: 20px;
}

.homeimg {
  width: 100%;
  border-radius: 15px;
}

.routerlink {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .router_li {
    width: 25%;

    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .imgbox {
      display: flex;
      align-items: center;
      justify-content: center;
      .imgbox_item {
        // width: 38px;
        // height: 38px;

        // border-radius: 50%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        img {
          margin-bottom: 6px;
          width: 24px;
          height: 24px;
        }
      }
    }

    span {
      color: var(--text-color);
      font-size: 13px;
      font-weight: 500;
      font-family: 'PingFang';
    }
  }
}

.notice {
  position: relative;
  margin-top: 30px;
  width: 333px;
  height: 60px;
  border-radius: 16px;
  opacity: 1;
  background: rgba(242, 242, 242, 1);
  padding: 10px;
  display: flex;
  align-items: center;

  img {
    height: 100%;
    margin-right: 10px;
  }

  span {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
  }

  .shoyi {
    position: absolute;
    right: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: url('~@/assets/imgsnowball/shoyiyou.png') no-repeat;
    width: 36px;
    height: 36px;
    background-size: contain;
  }
}

.d6 {
  color: #1d1d1d;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Poppins';
}

.jiasho {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .box {
    padding: 11px 12px;

    border-radius: 10px;
    height: 100%;
    background: #26272bff;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .jiage {
    .j_span1 {
      color: #ced0deff;
      font-size: 12px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .j_span2 {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
    }

    .j_span3 {
      color: #5fc88fff;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Poppins';
    }

    .j_div1 {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .shandui {
        color: var(--text-color);
        width: 41px;
        height: 21px;
        border-radius: 55px;
        opacity: 1;
        background: #808cf5;
        text-align: center;
        line-height: 21px;
        font-size: 10px;
        font-weight: 500;
        font-family: 'HarmonyOSSansSC';
      }
    }
  }

  .d_shoyi {
    position: relative;

    .s_div {
      align-self: flex-end;
      width: 41px;
      height: 21px;
      border-radius: 55px;
      position: absolute;
      bottom: 10px;
      background: #ffce57ff;
      color: #1c203aff;
      font-size: 10px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      text-align: center;
      line-height: 21px;
    }

    .s_span1 {
      color: #ffce57ff;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .s_span2 {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      width: 60%;
      display: flex;
      justify-content: space-between;
      // display: flex;
      // justify-content: space-between;
      // width: 50%;
    }
  }
}

.echathead {
  .e_span1 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
    margin-right: 20px;
  }

  .e_span2 {
    color: #9395a4ff;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';

    img {
      width: 12px;
      height: 12px;
    }
  }
}

#echat {
  margin: 0 auto;
}

.zhuzao {
  width: 100%;

  border-radius: 16px;
  opacity: 1;
  position: relative;
  background: var(--box-bg-color);

  border: 1px solid rgba(52, 247, 244, 0.49);
  padding: 15px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  .zhuzao_left {
    display: flex;
    flex-direction: column;
    .zhuzao_left_title {
      font-size: 16px;
      font-weight: bold;
      & > :first-child {
        color: rgba(55, 249, 252, 1);
      }
      & > :last-child {
        color: var(--text-color);
      }
    }
  }
  .zhuzao_button {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(20, 20, 20, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 16px;
    bottom: 22px;
    width: 91px;
    height: 36px;
    opacity: 1;
    border-radius: 36px;
    padding: 2px;
    background: rgba(152, 232, 110, 1);
    .down_icon {
      transform: rotate(-90deg);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background: var(--bg-color);
      color: var(--text-color);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  img {
    width: 111px;
    height: 67px;
    margin-top: 6px;
    margin-left: 20px;
  }
}

.zichantitle {
  display: flex;
  align-items: center;
  margin-top: 30px;

  .title {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
    margin-right: 40px;
  }

  //    /deep/.van-checkbox__icon--square{
  //     position: absolute;
  //     left: 1px;
  //     top: 5px;

  // }
  .yingcang {
    color: #999999ff;
    font-size: 12px;
    font-weight: 500;
    font-family: 'PingFang';
  }

  /deep/.van-checkbox__icon .van-checkbox__icon {
    border-color: #000 !important;
  }

  /deep/.van-checkbox__icon--checked .van-icon-success {
    color: #8b5411;
  }
}

.zichanlist {
  margin-top: 10px;
  width: 100%;
  // height: 213px;
  border-radius: 10px;
  background: rgba(242, 242, 242, 1);
  padding: 17px 14px;
  padding-top: 0;

  .li_title {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: #999999ff;
    font-size: 12px;
    font-weight: 700;
    font-family: 'PingFang';
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    padding-top: 17px;
    padding-bottom: 12px;
  }
}

.li_con {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  div {
    color: rgba(34, 34, 34, 1);
    width: 30%;
    text-align: center;
    line-height: 40px;
  }

  .li1 {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}

.overflow {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .gongaox {
    position: absolute;
    right: 21px;
  }

  .o_cont {
    position: relative;
    width: 335px;
    min-height: 250px;

    overflow: hidden;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px 21px;
    background: var(--line-bg-color);

    .line {
      background: var(--text-color);
      width: 100%;
      height: 1px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .title {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 350px;
      overflow-y: scroll;

      div {
        margin-bottom: 10px;
        white-space: pre-wrap;

        white-space: normal;
        word-break: break-word;
      }
    }
  }
}

.assets {
  padding-bottom: 20px;
  width: 100%;

  .a_title {
    color: #999999;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
  }

  .a_number {
    .num {
      color: #000;
      font-size: 36px;
      font-weight: 500;
      font-family: 'Poppins';
    }

    .tit {
      margin-left: 5px;
      color: #000;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Poppins';
    }
  }

  .a_shoyi {
    display: flex;

    .num {
      color: #c75d5d;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Poppins';
    }

    .tit {
      margin-left: 10px;
      color: #999999;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Poppins';
    }
  }
}
</style>