

export const lang = {
  swap1: '闪兑',
  swap2: '立刻开始闪兑',
  swap3: 'from',
  swap4: 'balance: ',
  swap5: '选择币种',
  swap6: 'max',
  swap7: '价格',
  swap8: '滑点容差',
  swap9: '连接钱包',
  swap10: '输入数量',
  swap11: '余额不足',
  swap12: '此交易的流动性不足',
  swap13: '选择代币',
  swap14: '授权',
  swap15: '包装',
  swap16: '解包',
  swap17: '闪兑',
  swap18: '交易收据',
  swap19: '正在等待确认',
  swap20: '正在将',
  swap21: '交换为',
  swap22: '在你的钱包种确认此交易',
  swap23: '已提交交易',
  swap24: '在区块浏览器查看',
  swap25: '关闭',
  swap26: '交易拒绝',
  swap27: '确认交换',
  swap28: '输出为估值，您将获得至少{inputOutMin} {symbol},或者交易将被撤回',
  swap29: '输入为估值，您将出售最多{inputInMax} {symbol},或者交易将被撤回',
  swap30: '最小获得量',
  swap31: '最大出售量',
  swap32: 'to',
  swap33: '管理代币',
  swap34: '导入代币',
  swap35: '导入',
  swap36: '管理Tokens',
  swap37: '自定义代币',
  swap38: '全部清除',
  swap39: '搜索名称或粘贴地址',
  swap40: '设置',
  swap41: '默认交易速度(GWEI)',
  swap42: '标准',
  swap43: '快速',
  swap44: '即时',
  swap46: '输入有效的滑点百分比',
  swap47: '你的交易可能会失败',
  swap48: '你的交易可能会被超前',
  swap49: '交易截止时间(分钟)',
  swap50: '估计',
  swap51: '禁用多跳',
  swap52: '最近交易',
  swap53: '最近没有交易',
  swap54: '全部清除',
  swap55: '未连接',
  swap56: '加入董事会',
  swap57: '捐赠',
  swap58: '权益赠送',
  swap59: '董事会成员列表',
  swap60: '邀请',
  swap61: '复制',
  swap62: '我的DAPP邀请人:',
  swap63: '邀请记录',
  swap64: '出错啦，请稍后再试',
  swap65: '功能暂未开放',
  swap66: '领取',
  swap67: '取出成功',
  swap68: '捐赠中，待确认',
  swap69: '已捐赠',
  swap70: '非白名单用户',
  swap71: '捐赠无效',
  swap72: '当前余额不足',
  swap73: '授权中，请稍等',
  swap74: '授权失败',
  swap75: '捐赠中，请稍等',
  swap76: '捐赠失败',
  swap77: '兑换',
  swap78: '流动性',
  swap79: '会员',
  swap80: '邀请',
  swap81: '权益池',
  swap82: '跨链桥',
  swap83: '池子',
  swap84: '农场',
  swap85: '首页',
  swap86: '市场',
  swap87: '兑换',
  swap88: '交易',
  swap89: '赚取',
  swap90: '确认',
  swap91: '价格影响',
  swap92: '最小获得量',
  swap93: '流动性供应商费用',
  swap94: '增加流动性',
  swap95: '增加流动性以接收 LP 代币',
  swap96: '流动性池中的份额',
  swap97: '兑换率和流动池份额',
  swap98: '您的流动性',
  swap99: '移除流动性以收回代币',
  swap100: '未找到流动性',
  swap101: '未看到您加入的流动池？',
  swap102: '查看其他LP代币',
  swap103: '已入池',
  swap104: '移除',
  swap105: '供应',
  swap106: '导入池',
  swap107: '导入现有流动性池',
  swap108: '选择代币以查找您的流动性。',
  swap109: '您在该流动性池中还没有提供流动性。',
  swap110: '您的钱包中的LP代币',
  swap111: '接收',
  swap112: '和',
  swap113: '金额',
  swap114: '你将获得',
  swap115: '输出为估值。如果价格变化超过 {slippage}%，则您的交易将被撤回',
  swap116: '已焚毁',
  swap117: '已入金',
  swap118: '确认供应',
  swap119: '汇率',
  swap120: '您是第一个流动性供应商。',
  swap121: '您添加代币的比列，将决定该流动性池的初始价格。',
  swap122: '如果您对汇率满意，请点击“供应”以进行检查。',
  swap123: '正在移除',
  swap124: '正在供应',
  swap125: '确认供应',
  swap126: '白皮书',
  swap127: '审计',
  swap128: '公告',
  swap129: '提示',
  swap130: 'PHO 1:1 兑换',
  swap131: '更多',
  swap132: '正在加载',
  swap133: '信息',
  swap134: '捐赠名额已满',
  swap135: '我的PHO',
  swap136: '钱包中的PHO',
  swap137: '可收割的PHO',
  swap138: 'PHO价格',
  swap139: 'PHO总量',
  swap140: 'PHO市值',
  swap141: 'PHO信息',
  swap142: '已赚取',
  swap143: '质押',
  swap144: '个人TVL',
  swap145: '社区TVL',
  swap146: '收割',
  swap147: '累计激励 ',
  swap148: '今日激励',
  swap149: '参与市商地址数',
  swap150: '我的邀请链接',
  swap151: '最大算力区',
  swap152: '其他算力区',
  swap153: '我的好友邀请',
  swap154: '取消',
  swap155: '修改邀请人',
  swap156: '邀请人不能为空',
  swap157: '修改成功',
  swap158: '质押LP代币',
  swap159: '赎回LP代币',
  swap160: '赎回',
  swap161: '获取',
  swap162: '当前暂无收益',
  swap163: '路由',
  swap164: '审计机构',
  swap165: '合作伙伴',
  swap166: '发行',
  swap167: '活动',
  swap168: '单币池',
  swap169: 'LP池',
  swap170: '国会',
  swap171: '卡牌',
  swap172: '盲盒',
  swap173: '游戏',
  swap174: '权益池已分配总额',
  swap175: '待领取数额',
  swap176: '我已获取数额',
  swap177: '取出',
  swap178: '超级节点',
  swap179: '权益卡已领取份额',
  swap180: '国库',
  swap181: '国库累计金额',
  swap182: '国库已分配金额',
  swap183: '国库已回购金额',
  swap184: '专家模式',
  swap185: 'GoodSwap是基于OKExChain研发的全网首个股权税收制Defi平台，逐步推出Swap、NFT、Gamefi、Lend、机枪池等板块。面世以来通过领先的技术和公平公开的市场理念，提供更加广泛的资产增值方案和前所未有的体验感，深受广大用户喜爱。',
  swap186: '一键取出',
  swap187: '价格影响过高',
  swap188: 'FAQ',
  swap189: 'NFT',
  swap190: '赚币',
  swap191: '进行中',
  swap192: '已结束',

  swap193: '说明',
  swap194: '股权权益生态卡，全球限量1680张',
  swap195: '点亮股权权益生态卡的要求及收益如下',
  swap196: '一星卡：个人地址拥有价值1000USDT流动性，团队1000USDT流动性，收益共分交易税收0.1%',
  swap197: '二星卡：个人拥有价值2000USDT流动性，团队1万USDT流动性，收益共分交易税收0.1%',
  swap198: '三星卡：个人3000USDT流动性，团队10万USDT流动性，设立工作室，收益共分交易税收0.1%',
  swap199: '四星卡：个人拥有价值4000USDT流动性,团队100万USDT流动性,设立工作室，收益共分交易税收0.1%',
  swap200: '五星卡：个人5000USDT流动性，团队1000万USDT流动性，设立工作室，收益共分交易税收0.1%',
  swap201: '备注：所有权益生态卡收益全部链上自动分配，权益卡享受交易所所有生态分红，权益卡可传承可交易可转让，满足要求自动升级，不满足要求自动降级及自动退出。',
  swap202: '权益卡',
  swap203: '发展路线图：',
  swap204: '2022年7月  2.0版上线',
  swap205: '2022年9月  开通持币多挖',
  swap206: '2022年12月 NFT交易平台上线',
  swap207: '2023年 6月  Goodbaby游戏上线',
  swap208: '2023年 9月  Goodlend上线',
  swap209: 'GoodSwap介绍：',
  swap210: '滑点：',
  swap211: '分配：',
  swap212: '买入',
  swap213: '卖出',
  swap214: '0.5% LP分红',
  swap215: '0.5% 生态权益卡分红',
  swap216: '0.25% 回流底池销毁LP',
  swap217: '0.25% 生态建设',
  swap218: '暂无奖励领取',
  swap219: '个人TVL',
  swap220: '团队TVL',
  swap221: 'LP数量',
  swap222: '钱包中的pho',
  swap223: 'GDT',
  swap224: 'GDT空投累计奖励',
  swap225: '星级用户累计奖励',
  swap226: '免手续费倒计时',
  swap227: '可取出奖励',
  swap228: 'LP挖矿奖励',
  swap229: '质押pho',
  swap230: '赎回pho',
  swap231: '目标共识',
  swap232: '已达共识',
  swap233: '已共识',
  swap234: '共识',
  swap235: '已解锁',
  swap236: '可领取',
  swap237: '社区治理',
  swap238: '联盟共识',
  swap239: '社区联盟',
  swap240: '质押代币',
  swap241: '质押中，请稍等',
  swap242: '交易挖矿',
  swap243: '昨日新增',
  swap244: '社区白名单累计奖励',
  swap245: '股权星卡累计奖励',
  swap246: 'LP挖矿累计奖励',
  swap247: '当前可领取',
  swap248: '当前可领取金额',
  swap249: '交易挖矿',
  swap250: '我的分享奖励累计',
  swap251: '我的交易奖励累计',
  swap252: '请输入您的交易哈希',
  swap253: '校验',
  swap254: '请输入您的哈希',
  swap255: '您的交易地址',
  swap256: '您将获得',
  swap257: '仅买入GDT及创建流动性的交易可获得奖励',
  swap258: '质押GDT-PHO代币',
  swap259: '赎回GDT-PHO代币',
  swap260: '全网手续费累计',
  swap261: '待领取分红',
  swap262: 'GDT联合股东',
  swap263: '社交',
  swap264: '商城',
  swap265: '精选好物',
  swap266: '优选小店',
  swap267: '即将上线',
  swap268: 'VGT联创股东',
  swap269: '质押VGT-PHO代币',
  swap270: '赎回VGT-PHO代币',
  swap271: '推荐链接',
  swap272: '请先添加邀请人',

  swap273: '详细',
  swap274: '元宇宙',
  swap275: '全局',
  swap276: '交换和流动性',
  swap277: '是基于OKExChain研发的全网首个股权税',
  swap278: '收制Defi平台，逐步推出',
  swap279: '机枪池等板块。面世以来通过领先的技术和公平公开的市场理念，提供更加广泛的资产增值方案和前所未有的体验感，深受广大用户喜爱。',
  swap280: '发展路线图',
  swap281: '2022年7月 2.0版上线',
  swap282: '2022年9月 开通持币多挖',
  swap283: '2022年12月 NFT交易平台上线',
  swap284: '2023年6月 Goodbaby游戏上线',
  swap285: '2023年9月 Goodlend上线',
  swap286: '了解更多',
  swap287: '您已持有',
  swap288: '在',
  swap289: '交易加密数字货币',
  swap290: '质押流动性和代币',
  swap291: '赚取分红奖励',
  swap292: '发射台',
  swap293: '认证',
  swap294: '优质加密数字资产',
  swap295: '了解',
  swap296: '艺术品',
  swap297: '交易市场',
  swap298: '浏览',
  swap299: '即时兑换代币',
  swap300: '复制成功',
  swap301: '不支持复制,该浏览器不支持自动复制',
  swap302: '联创累计奖励',
  swap303: '累计分享奖励',
  swap304: '累计交易奖励',
  swap305: '资产包',
  swap306: '交易赚币',
  swap307: '可取出余额',
  swap308: '请输入交易哈希',
  swap309: '确定',
  swap310:'请输入hash值',
  swap311:'入账成功',

  swap312:'币安',
  swap313:'平台',
  swap314:'PHO专区',
  swap315:'GDT专区',
  swap316:'VGT专区',
swap317:'Good1680星级卡',
swap318:'个人',
swap319:'团队',
swap320:'钱包中的',
swap321:'选择资产包',
swap322:'请选择',
swap323:'日收益率',
swap324:'收益比例',
swap325:'到期总数',
swap326:'购买',
swap327:'您的钱包余额',
swap328:'我的资产包',
swap329:'今日总收益',
swap330:'今日静态收益',
swap331:'今日动态收益',
swap332:'累计收益',
swap333:'到期数量',
swap334:'选择',
swap335:'加密货币专区',
swap336:'购买成功',
swap337:'兑换数量',
swap338:'请输入',
swap339:'空投',
swap340:'兑换成功',

swap341:'充值',
swap342:'提现',
swap343:'选择通行',
swap344:'充值数量',
swap345:'请输入充值数量',
swap346:'钱包余额',
swap347:'可用余额',
swap348:'提现数量',
swap349:'请输入提现数量',
swap350:'选择通证',
swap351:'账单',
swap352:'暂无数据',
swap353:'GOOD购',
swap354:'搜索',
swap355:'编号',
swap356:'人数',
swap357:'操作',
swap358:'拼团',
swap359:'发团',
swap360:'个人中心',
swap361:'发团将消耗一张团长券，发团后无法撤销，拼团成功后您将获得丰厚奖励',
swap362:'已持有团长券：{quantity}张',
swap363:'兑换资产包',
swap364:'余额',
swap365:'兑换消耗团长票',
swap366:'余额{quantity}张',
swap367:'拼团消耗',
swap368:'拼团编号',
swap369:'背包',
swap370:'已拥有团长票',
swap371:'已拥有商品票',
swap372:'我参与的拼团',
swap373:'角色',
swap374:'团员',
swap375:'拼团中',
swap376:'拼团失败',
swap377:'拼团成功',
swap378:'资产',
swap379:'隐藏零资产',
swap380:'名称',
swap381:'可用',
swap382:'团长',
swap383:'您持有{quantity}张',

swap384:'您没有团长券',
swap385:'发团成功',
swap386:'拼团成功',
swap387:'Good 购',
swap388:'Good Swap',
swap389:'拼团拿大奖',
swap390:'兑换数量不能为0',
swap391:'入账中，请稍后',
swap392:'不足',
swap393:'提币成功',
swap394:'我的拼团星级',
swap395:'拼单次数',
swap396:'下一等级',
swap397:'拼团消耗',
swap398:'不发团直接兑换',
swap399:'链游',
swap400:'更多板块陆续登录中...',
swap401:'已发放',
swap402:'选择网络',
swap403:'一星卡：直推100个有效，团队500个有效地址（最少有4条线各100个有效地址的团队），可获1000GDT 资产包赠送，并共分交易税收0.1%',
swap404:'二星卡：五条线分别出现 一星团队，可获2000GDT 资产包赠送，并共分交易税收0.1%',
swap405:'三星卡：五条线分别出现二星团队，可获5000GDT 资产包赠送，并共分交易税收0.1%',
swap406:'四星卡：五个团队分别出现三星团队，可获10000GDT 资产包赠送，并共分交易税收0.1%',
swap407:'五星卡：五个团队分别出现四星团队，可获50000GDT 资产包赠送，并共分交易税收0.1%',
swap408:'提示：',
swap409:'星级每日会变动，每个星级的仅赠送一次；',
swap410:'当前有效直推',
swap411:'当前有效团队',



header1:'推荐邀请码',
header2:'权益中心',
header3:'首页',
header4:'PHO专区',
header5:'GDT专区',
header6:'VGT专区',
header7:'娱乐游戏',
header8:'问题反馈',
header9:'增值商城',
header10:'社区联盟',
header11:'账单',
header12:'提币',
header13:'转账',
header14:'GDT中心',

header15:'GDT账单',
header16:'GDT个人中心',
header17:'VGT账单',
header18:'VGT个人中心',
header19:'GDT资产包',
header20:'VGT资产包',
header21:'VGT中心',


footer1:'首页',
footer2:'交易',
footer3:'矿池',
footer4:'空投',
footer5:'俱乐部',

home1:'资产',
home2:'币种',
home3:'账户',
home4:'钱包',
home5:'信息',
home6:'今日价格',
home7:'去交易',
home8:'供应总量',
home9:'去赚币',
home10:'交易税收',
home11:'双向',
home12:'行情',
home13:'热门',
home14:'关于',
home15:' GoodSwap是致力于多链建设的去中心化交易平台，以联盟经济为发展核心，倡导“社群经济”理念，发挥DAO组织结构优势，通过打造生态大数据，为用户创建安全、可靠、稳定、长期、多样化、跨地域、跨门槛的收益机遇！',
home16:'优势',
home17:'经验丰富',
home18:'团队核心来自新加坡、韩国等，并参与过SushiSwap、DODO等平台建设，有丰富去中心化平台经验！',
home19:'社区共建',
home20:'GoodSwap秉承：“一个中心、多个联盟”的经济理念，致力于充分发挥社区治理力量，实现DAO型治理组织。',
home21:'安全保障',
home22:'GoodSwap所有合约均开源并进行完善审计，对未开源及审查的通证将拒绝合作！',
home23:'联盟计划',
home24:'GoodSwap以生态联盟为核心，致力于推动多生态进驻，为用户提供安全、可靠稳定、多样化的收益机会！',
home25:'审计机构',
home26:'合作伙伴',
home27:'充币',
home28:'提币',
home29:'账单',
home30:'了解',
home31:'交易就来',
home32:'高效率',
home33:'多收益',
home34:'全新上线',
home35:'跨桥链',
home36:'公告列表',
home37:'公告详情',
home38:'币种',
home39:'最新价格',
home40:'涨跌幅',
home41:'转账',
home42:'转账数量',
home43:'请输入转账数量',
home44:'收款地址',
home45:'请输入收款地址',
home46:'实际转出',
home47:'余额不足',
home48:'请选择通证',
home49:"签名失败",

drop1:'常规空投',
drop2:'GoodSwap致力于为持币用户创造无限的价值和权益，当您保持账户持有1 GDT，即成为有效用户，次日起可获得空投权益，您需要每日领取，领取时保持账户有效。',
drop3:'挖矿规则',
drop4:'有效用户可领取日常空投，有效用户是指当您账户持有1枚GDT时，次日起可获得空投，领取时您需要保持账户有效。',
drop5:'日常空投存随机币种，根据平台发展和活动变化，您可及时留意相关信息，当日未领取的空投，不累计。您需要规定时间内领取。',
drop6:'GDT余额',
drop7:'已领次数',
drop8:'今日空投',
drop9:'币种列表',
drop10:'可领取',
drop11:'进入',
drop12:'领取',
drop13:'暂无奖励',
drop14:'未达到考核标准',


invite1:'我的邀请码',
invite2:'复制链接码',
invite3:'我的邀请人',
invite4:'请填写推荐人邀请码',
invite5:'最大算力区',
invite6:'其他算力区',
invite7:'搜索',
invite8:'填写邀请码',
invite9:'确定',
invite10:'请绑定推荐人',
invite11:'暂未记录',


};
